import { useState, useRef, useEffect } from "react";
import { Modal, Button, ProgressBar } from "react-bootstrap";
import useAPI from "../../../../../hooks/useAPI";
import { useForm } from "react-hook-form";
import { Chip, IconButton, Tooltip, Stack } from "@mui/material";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import TemplateService from "../../../../../services/TemplateService";
import InputErrorMessage from "../../../../utils/InputErrorMessage";
import RegexUtils from "../../../../../utils/Regex";
import FileUtils from "../../../../../utils/File.js";
import AppConstants from "../../../../../constants/App";
import { useSnackbar } from "notistack";
import SnackbarConstants from "../../../../../constants/Snackbar.js";
import ScannedWebPagesModal from "./ScannedWebPagesModal.js";

import JSZip from "jszip";

const AddKBModal = ({ show, showHideHandler, data, getAllKnowledgeBase }) => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    register,
    unregister,
    watch,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({ criteriaMode: "all", mode: "onTouched" });

  const [resourceTypeInput, setResourceTypeInput] = useState("document");

  const [documents, setDocuments] = useState([]);
  const documentsRef = useRef(documents);

  const [mediaAttachments, setMediaAttachments] = useState([]);

  const [showScannedWebPagesModal, setShowScannedWebPagesModal] =
    useState(false);
  const [scannedWebPages, setScannedWebPages] = useState([]);

  const [isDocumentZipping, setIsDocumentZipping] = useState(false);

  const [documentUploadProgress, setDocumentUploadProgress] = useState(0);

  const [integrityKey, setIntegityKey] = useState("");
  const integrityKeyRef = useRef(integrityKey);

  const addKnowledgeBaseDocumentAPI = useAPI(
    TemplateService.addKnowledgeBaseDocument,
    addKnowledgeBaseHandler
  );

  const prepareDocumentUploadAPI = useAPI(
    TemplateService.prepareDocumentUpload,
    prepareDocumentUploadHandler
  );

  const uploadDocumentToUrlAPI = useAPI(
    TemplateService.uploadDocumentToUrl,
    uploadDocumentToUrlHandler
  );

  const processUploadedDocumentAPI = useAPI(
    TemplateService.processUploadedDocument,
    () => {}
  );

  const addKnowledgeBaseWebPageAPI = useAPI(
    TemplateService.addKnowledgeBaseWebPage,
    addKnowledgeBaseHandler
  );
  const scanWebPageNestedUrlsAPI = useAPI(
    TemplateService.scanWebPageNestedUrls,
    scanWebPageNestedUrlsHandler
  );
  const addKnowledgeBaseQaAPI = useAPI(
    TemplateService.addKnowledgeBaseQA,
    addKnowledgeBaseHandler
  );
  const addKnowledgeBaseMediaAPI = useAPI(
    TemplateService.addKnowledgeBaseMedia,
    addKnowledgeBaseHandler
  );

  useEffect(() => {
    documentsRef.current = documents; // Update the ref whenever documents state changes

    integrityKeyRef.current = integrityKey;
  }, [documents, integrityKey]);

  // Event Handlers

  function addKnowledgeBase(formData) {
    if (resourceTypeInput === "document") {
      if (documents.length === 0) {
        enqueueSnackbar(
          "Upload at least one document",
          SnackbarConstants.error
        );
        return;
      }

      if (!validateFileSize(documents)) return;

      formData["documents"] = documents;

      delete formData["attachments"];

      formData["filenames"] = documents.map((file) => file.name);

      prepareDocumentUploadAPI.execute(data.chatbotId, formData);
    } else if (resourceTypeInput === "web page") {
      let isNestedPageSelected = formData.webPageScanType === "nested";

      // extract the nested urls of the web page
      if (isNestedPageSelected && scannedWebPages.length === 0) {
        scanWebPageNestedUrlsAPI.execute(data.chatbotId, formData.url);
        return;
      }
      if (isNestedPageSelected) {
        let urls = scannedWebPages
          .filter((data) => data.checked)
          .map((data) => data.url);

        formData.urls = urls;
      }
      delete formData["url"];
      addKnowledgeBaseWebPageAPI.execute(data.chatbotId, formData);
    } else if (resourceTypeInput === "qa") {
      let qas = [];
      for (let prop in formData) {
        if (prop.includes("question")) {
          qas.push({
            question: formData[prop],
            answer: formData["answer-" + prop.split("-")[1]],
          });
        }
      }

      addKnowledgeBaseQaAPI.execute(data.chatbotId, {
        name: formData.name,
        description: formData.description,
        data: qas,
      });
    } else if (resourceTypeInput === "media") {
      delete formData["document"];
      formData["attachments"] = mediaAttachments;
      addKnowledgeBaseMediaAPI.execute(data.chatbotId, formData);
    }
  }

  function validateFileSize(files) {
    const isValid = FileUtils.validateFileSize(files, 1024);

    if (!isValid) {
      enqueueSnackbar(
        `Cannot upload file(s) larger than 1 GB`,

        SnackbarConstants.error
      );
    }

    return isValid;
  }

  function onUploadProgress(progressEvent) {
    const percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );

    setDocumentUploadProgress(percentCompleted);
  }

  function requestDocumentProcess() {
    processUploadedDocumentAPI.execute(data.chatbotId, {
      integrityKey: integrityKeyRef.current,
    });
  }

  /* API success handlers */

  function prepareDocumentUploadHandler(response) {
    setIsDocumentZipping(true);

    const integrityKey = response.data.integrityKey;

    setIntegityKey(integrityKey);

    const uploadUrl = response.data.uploadUrl;

    // zip the documents

    const zip = new JSZip();

    Array.from(documentsRef.current).forEach((file) => {
      zip.file(file.name, file);
    });

    zip.generateAsync({ type: "blob" }).then((content) => {
      // upload zip file to provided url

      uploadDocumentToUrlAPI.execute(uploadUrl, content, onUploadProgress);
    });

    setIsDocumentZipping(false);
  }

  function uploadDocumentToUrlHandler(response) {
    showHideHandler(false);

    // send post process request to server
    requestDocumentProcess();

    getAllKnowledgeBase();
  }

  function addKnowledgeBaseHandler(response) {
    showHideHandler(false);
    getAllKnowledgeBase();
  }

  function scanWebPageNestedUrlsHandler(response) {
    setScannedWebPages(response.data);
    showHideHandler(false);
    setShowScannedWebPagesModal(true);
  }

  const isPending =
    prepareDocumentUploadAPI.status === "pending" ||
    isDocumentZipping ||
    uploadDocumentToUrlAPI.status === "pending" ||
    addKnowledgeBaseDocumentAPI.status === "pending" ||
    addKnowledgeBaseWebPageAPI.status === "pending" ||
    scanWebPageNestedUrlsAPI.status === "pending" ||
    addKnowledgeBaseQaAPI.status === "pending" ||
    addKnowledgeBaseMediaAPI.status === "pending";

  return (
    <>
      <Modal
        className="modal fade"
        show={show}
        onHide={() => showHideHandler(false)}
        scrollable
        size="lg"
      >
        <div role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Knowledge Base</h5>
              <button
                type="button"
                onClick={() => showHideHandler(false)}
                className="close"
                data-dismiss="modal"
              >
                <span>&times;</span>{" "}
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit(addKnowledgeBase)}>
                <div className="form-group">
                  <label className="text-black font-w500" htmlFor="nameInput">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    id="nameInput"
                    {...register("name", {
                      required: "Name is required",
                      minLength: {
                        value: 1,
                        message: "Name should have at least 1 char",
                      },
                      maxLength: {
                        value: 70,
                        message: "Name should have at most 70 chars",
                      },
                    })}
                  />
                  <InputErrorMessage name="name" errors={errors} />
                </div>
                <div className="form-group">
                  <label className="text-black font-w500">
                    Description <small>(Optional)</small>
                  </label>
                  <textarea
                    cols="30"
                    rows="2"
                    className="form-control bg-transparent"
                    placeholder="Type something about this knowledge resource"
                    name="description"
                    id="descriptionInput"
                    {...register("description", {
                      maxLength: {
                        value: 300,

                        message: "Description should have at most 300 chars",
                      },
                    })}
                  />
                  <InputErrorMessage name="description" errors={errors} />
                </div>
                <div className="form-group">
                  <label className="text-black font-w500">Type</label>
                  <div>
                    <label className="radio-inline mr-3">
                      <input
                        type="radio"
                        value="document"
                        onChange={(e) => setResourceTypeInput("document")}
                        checked={resourceTypeInput === "document"}
                      />{" "}
                      Document
                    </label>
                    <label className="radio-inline mr-3">
                      <input
                        type="radio"
                        value="web page"
                        onChange={(e) => setResourceTypeInput("web page")}
                        checked={resourceTypeInput === "web page"}
                      />{" "}
                      Web Page
                    </label>
                    <label className="radio-inline mr-3">
                      <input
                        type="radio"
                        value="qa"
                        onChange={(e) => setResourceTypeInput("qa")}
                        checked={resourceTypeInput === "qa"}
                      />{" "}
                      QA
                    </label>
                    <label className="radio-inline mr-3">
                      <>
                        <input
                          type="radio"
                          value="media"
                          onChange={(e) => setResourceTypeInput("media")}
                          checked={resourceTypeInput === "media"}
                        />{" "}
                        Media
                      </>
                    </label>
                  </div>
                </div>
                {
                  <KBResourceTypeInput
                    resourceType={resourceTypeInput}
                    register={register}
                    unregister={unregister}
                    watch={watch}
                    errors={errors}
                    documents={documents}
                    setDocuments={setDocuments}
                    mediaAttachments={mediaAttachments}
                    setMediaAttachments={setMediaAttachments}
                  />
                }
                <div className=" mt-4 mb-0 d-flex flex-row justify-content-between align-items-center">
                  <button
                    type="submit"
                    className="btn btn-primary mr-3"
                    disabled={!isValid || isPending}
                  >
                    {isPending ? (
                      <>
                        <div
                          className="spinner-border spinner-border-sm text-light mr-2"
                          role="status"
                        >
                          <span className="visually-hidden"></span>
                        </div>
                        ADD
                      </>
                    ) : (
                      "ADD"
                    )}
                  </button>
                  {isDocumentZipping ||
                  uploadDocumentToUrlAPI.status === "pending" ? (
                    <div className="flex-grow-1">
                      <small>Uploading...</small>

                      <ProgressBar
                        now={documentUploadProgress}
                        variant="info"
                        striped
                      />
                    </div>
                  ) : null}
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
      <ScannedWebPagesModal
        show={showScannedWebPagesModal}
        showHideHandler={setShowScannedWebPagesModal}
        scannedWebPages={scannedWebPages}
        setScannedWebPages={setScannedWebPages}
        handleSubmit={handleSubmit(addKnowledgeBase)}
        isSubmitting={addKnowledgeBaseWebPageAPI.status === "pending"}
      />
    </>
  );
};

const KBResourceTypeInput = ({
  resourceType,
  register,
  unregister,
  watch,
  errors,
  documents,
  setDocuments,
  mediaAttachments,
  setMediaAttachments,
}) => {
  const fileInputRef = useRef(null);
  function isValidUrl(value) {
    const extension = value.split(".").pop().toLowerCase();
    return !AppConstants.invalidKbWebUrlExtensions.includes(extension);
  }

  const [urls, setUrls] = useState([""]);
  const [qas, setQas] = useState([0]);

  // handlers

  const handleFileUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    if (files.length > 0) {
      setDocuments([...documents, ...files]);
    }
  };

  function handleFileRemove(index) {
    setDocuments((prevState) => {
      prevState.splice(index, 1);
      return [...prevState];
    });
  }

  const addUrl = () => {
    setUrls([...urls, ""]);
  };

  const handleUrlChange = (index, value) => {
    const newUrls = [...urls];
    newUrls[index] = value;
    setUrls(newUrls);
  };

  const removeUrl = (index) => {
    if (urls.length > 1) {
      const newUrls = [...urls];
      newUrls.splice(index, 1);
      setUrls(newUrls);
      unregister(`urls[${index}]`);
    }
  };

  if (resourceType === "document") {
    return (
      <div>
        <small>Supported document formats: pdf, doc, txt, csv, xlsx</small>
        <Button
          variant="outline-primary"
          size="sm"
          className="d-block mt-2"
          onClick={handleFileUploadClick}
        >
          Upload Files
        </Button>
        <input
          type="file"
          multiple
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
        <Stack direction="row" spacing={1} mt={1} flexWrap="wrap" useFlexGap>
          {documents.map((file, index) => {
            return (
              <Chip
                key={`file-${index}`}
                label={file.name}
                size="small"
                variant="outlined"
                onDelete={() => handleFileRemove(index)}
              />
            );
          })}
        </Stack>
      </div>
    );
  } else if (resourceType === "web page") {
    return (
      <div className="form-group">
        <label className="text-black font-w500" htmlFor="urlInput">
          URL
        </label>
        <div className="mb-2">
          <label className="radio-inline mr-3">
            <input
              type="radio"
              name="webPageScanType"
              value="single"
              defaultChecked
              {...register("webPageScanType", {
                required: "Choose scan type",
              })}
            />{" "}
            Scan single page{" "}
            <Tooltip title="Scans the web page content of the given URL only">
              <InfoOutlinedIcon fontSize="xsmall" color="disabled" />
            </Tooltip>
          </label>
          <label className="radio-inline mr-3">
            <input
              type="radio"
              name="webPageScanType"
              value="nested"
              {...register("webPageScanType", {
                required: "Choose scan type",
              })}
            />{" "}
            Scan nested pages{" "}
            <Tooltip title="Scans the web page and the nested pages content of the given URL. Ex: Giving https://tymbot.io/documentation will scan all pages under /documentation/*">
              <InfoOutlinedIcon fontSize="xsmall" color="disabled" />
            </Tooltip>
          </label>
        </div>
        {watch("webPageScanType") !== "nested" ? (
          <div>
            {urls.map((url, index) => (
              <div key={index} className="d-flex align-items-center mb-1">
                <div className="flex-grow-1">
                  <input
                    type="text"
                    className="form-control"
                    name={`urls[${index}]`}
                    id={`urlInput${index}`}
                    {...register(`urls[${index}]`, {
                      required: "URL is required",
                      pattern: {
                        value: RegexUtils.URL,
                        message: "URL is invalid",
                      },
                      validate: (value) =>
                        isValidUrl(value) || "URL has an invalid extension",
                    })}
                    value={url}
                    onChange={(e) => handleUrlChange(index, e.target.value)}
                  />
                  {errors.urls && errors.urls[index] && (
                    <InputErrorMessage
                      errors={errors}
                      name={`urls[${index}]`}
                    />
                  )}
                </div>
                {urls.length <= 1 ? null : (
                  <IconButton
                    aria-label="delete"
                    color="error"
                    size="small"
                    onClick={() => removeUrl(index)}
                  >
                    <DeleteOutlineRoundedIcon fontSize="inherit" />
                  </IconButton>
                )}
              </div>
            ))}
          </div>
        ) : (
          // Nested URL Input
          <div>
            <input
              type="text"
              className="form-control"
              name="url"
              id="urlInput"
              {...register(`url`, {
                required: "URL is required",
                pattern: {
                  value: RegexUtils.URL,
                  message: "URL is invalid",
                },
                validate: (value) =>
                  isValidUrl(value) || "URL has an invalid extension",
              })}
            />

            <InputErrorMessage errors={errors} name="url" />
          </div>
        )}

        {watch("webPageScanType") !== "nested" && (
          <p className="my-2">
            Add another URL{" "}
            <IconButton color="info" className="p-0" onClick={addUrl}>
              <AddCircleOutlineRoundedIcon />
            </IconButton>
          </p>
        )}
      </div>
    );
  } else if (resourceType === "qa") {
    return (
      <>
        {qas.map((qaIndex) => {
          if (qaIndex === undefined) {
            return null;
          }
          return (
            <div
              key={`kb-qa-${qaIndex}`}
              className="d-flex justify-content-between align-items-start p-1 mb-2 rounded shadow-sm"
            >
              <div className="flex-grow-1 p-3">
                <div className="mb-3">
                  <label
                    className="text-black font-w500"
                    htmlFor={`questionInput-${qaIndex}`}
                  >
                    Question
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name={`question-${qaIndex}`}
                    id={`questionInput-${qaIndex}`}
                    {...register(`question-${qaIndex}`, {
                      required: "question is required",
                    })}
                  />
                  <InputErrorMessage
                    name={`question-${qaIndex}`}
                    errors={errors}
                  />
                </div>
                <div>
                  <label
                    className="text-black font-w500"
                    htmlFor={`answerInput-${qaIndex}`}
                  >
                    Answer
                  </label>
                  <textarea
                    cols="30"
                    rows="3"
                    className="form-control bg-transparent"
                    name={`answer-${qaIndex}`}
                    id={`answerInput-${qaIndex}`}
                    {...register(`answer-${qaIndex}`, {
                      required: "answer is required",
                    })}
                  />
                  <InputErrorMessage
                    name={`answer-${qaIndex}`}
                    errors={errors}
                  />
                </div>
              </div>
              <IconButton
                aria-label="delete"
                color="error"
                className="kb-qa-delete-icon"
                size="small"
                onClick={() => {
                  qas[qaIndex] = undefined;
                  unregister(`question-${qaIndex}`);
                  unregister(`answer-${qaIndex}`);
                  setQas([...qas]);
                }}
              >
                <DeleteOutlineRoundedIcon fontSize="inherit" />
              </IconButton>
            </div>
          );
        })}
        <p className="mb-2">
          Add question and answer{" "}
          <IconButton
            color="info"
            className="p-0"
            onClick={() => {
              setQas([...qas, qas.length]);
            }}
          >
            <AddCircleOutlineRoundedIcon />
          </IconButton>
        </p>
      </>
    );
  } else if (resourceType === "media") {
    return (
      <>
        <div className="form-group">
          <label className="text-black font-w500">Content</label>
          <textarea
            cols="30"
            rows="3"
            className="form-control bg-transparent"
            placeholder="Write few lines describing the media"
            name="content"
            id="contentInput"
            {...register("content", {
              required: "Content is required",
              minLength: {
                value: 100,
                message: "Minimum 100 characters",
              },
            })}
          />
          <InputErrorMessage name="content" errors={errors} />
        </div>
        <small>
          Supported attachment formats:{" "}
          {AppConstants.kbMediaSupportedFiles.join(", ")}
        </small>
        <div className="input-group custom_file_input mb-4">
          <div className="custom-file">
            <input
              type="file"
              multiple
              className="custom-file-input"
              name="attachments"
              id="attachmentsInput"
              onChangeCapture={(e) => {
                setMediaAttachments([...mediaAttachments, ...e.target.files]);
              }}
              accept={AppConstants.kbMediaSupportedFiles.reduce(
                (builder, type) => {
                  return (builder += "." + type + ",");
                },
                ""
              )}
            />
            <label className="custom-file-label" htmlFor="attachmentsInput">
              Choose Attachments
            </label>
          </div>
        </div>
        <small>Attachments:</small>
        <div className="d-flex flex-wrap">
          {mediaAttachments.map((attachment, index) => {
            return (
              <Chip
                key={`attachment-${index}`}
                label={attachment.name}
                variant="outlined"
                className="mr-2 mb-2"
                onDelete={() => {
                  setMediaAttachments((prevState) => {
                    let index = prevState.findIndex(
                      (file) => file.name === attachment.name
                    );
                    prevState.splice(index, 1);
                    return [...prevState];
                  });
                }}
              />
            );
          })}
        </div>
      </>
    );
  } else {
    return null;
  }
};

export default AddKBModal;
